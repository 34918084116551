import React from 'react'
import CarrierBanner from './CarrierBanner/CarrierBanner'

function Carrier() {
  return (
    <div>
        <CarrierBanner/>
    </div>
  )
}

export default Carrier
