import React from 'react'
import Servicecontactus from './Constactus/Servicecontactus'
import Processtree from './Processtree/Processtree'
import ServiceBaner from './ServiceBanner/ServiceBaner'
import Services from './Services/Services'
// import Servicepayments from './Servicepayments/Servicepayments'

function Service() {
  return (
    <div>
        {/* <ServiceBaner/>
        <Services/>
        <Processtree/>
        <Servicecontactus/> */}
      
    </div>
  )
}

export default Service
