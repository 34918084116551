import React, { Fragment } from 'react'
import AboutDoctorBanner from './AbboutDoctorBanner/AboutDoctorBanner'
import DoctorKnowMore from './Doctorknowmore/DoctorKnowMore'
// import Testimonial from './Testimonials/Testimonial'
// import VideoContainer from './VideoContainer/VideoContainer'

function Aboutdoctor() {
  return (
    <Fragment>
        {/* <AboutDoctorBanner/> */}
        <DoctorKnowMore/>
        {/* <VideoContainer/> */}
        
    </Fragment>
    
      
    
  )
}

export default Aboutdoctor
